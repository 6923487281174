// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
    background-color: #02231E;
    color: white;
    padding: 1rem 0;
  }
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .footer-logo {
    text-decoration: none;
    color: #97FBE4;
    transition: opacity 0.3s ease;
  }
  .footer-logo:hover {
    opacity: 0.8;
  }
  .footer-logo h1 {
    font-family: 'Libre Baskerville', serif;
    font-size: 2em;
    font-weight: 700;
    margin: 0;
  }
  .footer-disclaimer {
    font-size: 0.875rem;
    color: #a0aec0;
  }
  .footer-social {
    display: flex;
    align-items: center;
  }
  .follow-us {
    margin-right: 1rem;
  }
  .social-icon {
    color: white;
    margin: 0 0.5rem;
    transition: color 0.3s ease;
  }
  .social-icon:hover {
    color: #97FBE4;
  }
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      text-align: center;
    }
    .footer-logo, .footer-disclaimer, .footer-social {
      margin-bottom: 1rem;
    }
  }`, "",{"version":3,"sources":["webpack://./src/css/footer/footer.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,eAAe;EACjB;EACA;IACE,iBAAiB;IACjB,cAAc;IACd,eAAe;IACf,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;EACrB;EACA;IACE,qBAAqB;IACrB,cAAc;IACd,6BAA6B;EAC/B;EACA;IACE,YAAY;EACd;EACA;IACE,uCAAuC;IACvC,cAAc;IACd,gBAAgB;IAChB,SAAS;EACX;EACA;IACE,mBAAmB;IACnB,cAAc;EAChB;EACA;IACE,aAAa;IACb,mBAAmB;EACrB;EACA;IACE,kBAAkB;EACpB;EACA;IACE,YAAY;IACZ,gBAAgB;IAChB,2BAA2B;EAC7B;EACA;IACE,cAAc;EAChB;EACA;IACE;MACE,sBAAsB;MACtB,kBAAkB;IACpB;IACA;MACE,mBAAmB;IACrB;EACF","sourcesContent":["footer {\n    background-color: #02231E;\n    color: white;\n    padding: 1rem 0;\n  }\n  .footer-content {\n    max-width: 1200px;\n    margin: 0 auto;\n    padding: 0 1rem;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n  .footer-logo {\n    text-decoration: none;\n    color: #97FBE4;\n    transition: opacity 0.3s ease;\n  }\n  .footer-logo:hover {\n    opacity: 0.8;\n  }\n  .footer-logo h1 {\n    font-family: 'Libre Baskerville', serif;\n    font-size: 2em;\n    font-weight: 700;\n    margin: 0;\n  }\n  .footer-disclaimer {\n    font-size: 0.875rem;\n    color: #a0aec0;\n  }\n  .footer-social {\n    display: flex;\n    align-items: center;\n  }\n  .follow-us {\n    margin-right: 1rem;\n  }\n  .social-icon {\n    color: white;\n    margin: 0 0.5rem;\n    transition: color 0.3s ease;\n  }\n  .social-icon:hover {\n    color: #97FBE4;\n  }\n  @media (max-width: 768px) {\n    .footer-content {\n      flex-direction: column;\n      text-align: center;\n    }\n    .footer-logo, .footer-disclaimer, .footer-social {\n      margin-bottom: 1rem;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
