import React, { useState } from 'react';
import SymbolTradeChartByTurnover from './symbolTradeChart_turnover';
import SymbolTradeChartByTrade from './symbolTradeChart_trade';
import TopTokenPnLChart from './token_pnl_bar_chart';
import { TrendingDown, Clock, Percent, Calendar } from 'lucide-react';
import { Tooltip } from 'antd';
import SemiCircleGauge from './semi_circle_gauge';
import TradeProgressBar from './longShortProgesstPercentage';

import './../../css/dashboard/topMetrics.css';
import { formatNumber, ToggleButtonGroup } from '../utils/utils';

const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    });
};

const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    });
};


const TopMetrics = ({ walletData }) => {
    const data_metrics = walletData.all_metrics;
    const [selectedChart, setSelectedChart] = useState({ key: 'turnover', value: 'Top Symbols By Turnover' });
    const [profitablity, setProfitablity] = useState({ key: 'all_time_profitable_trade', value: 'All Time Profitablity Trade' });


    const chartOptions = [
        { key: 'turnover', value: 'Top Symbols By Turnover' },
        { key: 'trade', value: 'Top Symbols By Trade' },
        { key: 'pnl', value: 'Top Symbols Token PnL' }
    ];

    const profitableOptions = [
        { key: 'all_time_profitable_trade', value: '% Profitablity Trade' },
        { key: 'longShortProfitablity', value: 'Long/Short Profitability Trade' },
    ];

    const formatValue = (val) => {
        if (val >= 1000000) {
            return (val / 1000000).toFixed(2) + 'M';
        } else if (val >= 1000) {
            return (val / 1000).toFixed(2) + 'K';
        } else {
            return val.toFixed(2);
        }
    };

    const TopPerformanceMetrics = () => (
        <div className=''>
            <div className="top_metrics_card">
                <div className="trading_overview_heading">
                    <h3>Trading Overview</h3>
                </div>
                <div className="top_metrics_content">
                    <div className="top_metrics_content_heading">Total Trades</div>
                    <div className="top_metrics_content_value">{formatNumber(data_metrics.total_trades)}</div>
                </div>
                <div className="top_metrics_content">
                    <div className="top_metrics_content_heading">Total Turnover</div>
                    <div className="top_metrics_content_value">$ {formatValue(data_metrics.total_turnover)}</div>
                </div>
                <div className="top_metrics_content">
                    <div className="top_metrics_content_heading">Symbols Traded</div>
                    <div className="top_metrics_content_value">{formatNumber(data_metrics.total_symbols_traded)}</div>
                </div>
                <div className="top_metrics_content">
                    <div className="top_metrics_content_heading">Daily Trades</div>
                    <div className="top_metrics_content_value">{formatNumber(data_metrics.avg_daily_trade)}</div>
                </div>
                <div className="top_metrics_content">
                    <div className="top_metrics_content_heading">
                        <span>Avg Return per Trade</span> &nbsp;  <Percent size={20} />
                    </div>
                    <div className="top_metrics_content_value">
                        <span>
                            {formatNumber(data_metrics.avg_ret_trade_bps)} bps
                        </span>
                        {/* &nbsp; {data_metrics.avg_ret_trade_bps > 0 ? <TrendingUp color={'green'} size={16} /> : <TrendingDown color={'red'} size={16} />} */}

                    </div>
                </div>
                <div className="top_metrics_content">
                    <div className="top_metrics_content_heading">
                        <span>Max Drawdown</span>  &nbsp; <TrendingDown size={20} />
                    </div>
                    <div className="top_metrics_content_value">
                        <span>$ {formatNumber(data_metrics.max_dd)}</span>
                        {/* &nbsp; {data_metrics.max_dd > 0 ? <TrendingUp color={'green'} size={16} /> : <TrendingDown color={'red'} size={16} />} */}

                    </div>
                </div>
                <div className="top_metrics_content">
                    <div className="top_metrics_content_heading">
                        <span>Avg Hold Time</span>  &nbsp; <Clock size={20} />
                    </div>
                    <div className="top_metrics_content_value">
                        <span>{formatNumber(Math.round(data_metrics['hold_time(min)']))} Min</span>
                        {/* &nbsp; {data_metrics['hold_time(min)'] > 400 ? <TrendingUp color={'green'} size={16} /> : <TrendingDown color={'red'} size={16} />} */}
                    </div>
                </div>
                {/* <div className="top_metrics_content">
                    <div className="top_metrics_content_heading">
                        <span>Trading Duration</span>  &nbsp; <Clock size={20} />
                    </div>
                    <div className="top_metrics_content_value">
                        {Math.floor((new Date() - new Date(data_metrics.start_trade_time)) / (1000 * 60 * 60 * 24))} days
                    </div>
                </div> */}
                <Tooltip title={formatDateTime(data_metrics.last_trade_time)}>
                    <div className="top_metrics_content">
                        <div className="top_metrics_content_heading">
                            <span>Last Trade</span>  &nbsp; <Calendar size={20} />
                        </div>

                        <div className="top_metrics_content_value">
                            {formatDate(data_metrics.last_trade_time)}  {new Date(data_metrics.last_trade_time).toLocaleString('en-US', { weekday: 'long' })}
                        </div>

                    </div>
                </Tooltip>
                <Tooltip title={formatDateTime(data_metrics.start_trade_time)}>
                    <div className="top_metrics_content">
                        <div className="top_metrics_content_heading">
                            <span>First Trade</span>  &nbsp; <Calendar size={20} />
                        </div>

                        <div className="top_metrics_content_value">
                            Since {formatDate(data_metrics.start_trade_time)}
                        </div>

                    </div>
                </Tooltip>
            </div>
        </div >
    )

    const MostProfitableTokenAndTrades = () => (
        <div className="top_metrics_top_perfoamce_card">
            <div>
                <ToggleButtonGroup
                    options={profitableOptions}
                    selectedOption={profitablity}
                    setSelectedOption={setProfitablity}
                />
                {profitablity.key === 'all_time_profitable_trade' &&
                    <div className="top_metrics_card top_metrics_gauge_card">
                        {/* <div className="Profitable_trade_meter_text">Profitable Trades</div> */}
                        <SemiCircleGauge
                            percentage={data_metrics.pct_prof_trade} />

                    </div>
                }
                {profitablity.key === 'longShortProfitablity' &&
                    <div className="top_metrics_card top_metrics_gauge_card">
                        {/* <div className="Profitable_trade_meter_text">Long/Short Profitability</div> */}
                        <TradeProgressBar
                            longPercentage={data_metrics.pct_long_trade}
                            longProfitablePercentage={data_metrics.pct_long_prof_trade}
                            shortProfitablePercentage={data_metrics.pct_short_prof_trade}
                        />
                    </div>
                }
            </div>

            <div className="top_metrics_card">
                <div className='profitable_token_card'>
                    <div className='profitable_token_card_heading'>
                        <span>Most Profitable Token</span>
                    </div>
                    <div className='profitable_token_card_content'>
                        <p className="profitable_token_card_text">{data_metrics.most_prof_symbol}</p>
                        <p className="profitable_token_card_sub_text">
                            Profit: {formatNumber(data_metrics.most_prof_symbol_abs_pnl)}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )

    const renderSelectedChart = () => {
        switch (selectedChart.key) {
            case 'turnover':
                return <SymbolTradeChartByTurnover symbol_wise_metrics={walletData.symbol_wise_metrics} />;
            case 'trade':
                return <SymbolTradeChartByTrade symbol_wise_metrics={walletData.symbol_wise_metrics} />;
            case 'pnl':
                return <TopTokenPnLChart symbol_wise_metrics={walletData.symbol_wise_metrics} />;
            default:
                return null;
        }
    }

    return (
        <>
            <div className="top_metrics_main_grid">
                <TopPerformanceMetrics />
                <MostProfitableTokenAndTrades />
                <div>
                    <ToggleButtonGroup
                        options={chartOptions}
                        selectedOption={selectedChart}
                        setSelectedOption={setSelectedChart}
                    />
                    {renderSelectedChart()}
                </div>
            </div>
        </>
    );
};

export default TopMetrics;