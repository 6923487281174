import React, { useState } from 'react';
import { ArrowUp, ArrowDown, ArrowUpDown } from 'lucide-react';
import "./../../css/dashboard/symbolMetricsTable.css";

import { formatNumber, symbol_dict } from '../utils/utils';

const SymbolMetricsTable = ({ symbol_wise_metrics }) => {
    symbol_wise_metrics = symbol_wise_metrics.map(item => ({
        ...item,
        Symbol: symbol_dict[item.Symbol] ? symbol_dict[item.Symbol].split("/")[0] : item.Symbol.split("/")[0]
    }));
    const [sortConfig, setSortConfig] = useState({ key: 'turnover', direction: 'descending' });

    const sortedData = React.useMemo(() => {
        let sortableItems = [...symbol_wise_metrics];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [symbol_wise_metrics, sortConfig]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = (columnName) => {
        if (sortConfig.key === columnName) {
            return sortConfig.direction === 'ascending' ?
                <ArrowUp className="sort-icon" size={16} /> :
                <ArrowDown className="sort-icon" size={16} />;
        }
        return <ArrowUpDown className="sort-icon" size={16} />;
    };

    return (
        <div className="symbol-metrics-table">
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th onClick={() => requestSort('Symbol')}>
                                Symbol {getSortIcon('Symbol')}
                            </th>
                            <th onClick={() => requestSort('turnover')}>
                                Turnover ($) {getSortIcon('turnover')}
                            </th>
                            <th onClick={() => requestSort('PnL')}>
                                PnL ($) {getSortIcon('PnL')}
                            </th>
                            <th onClick={() => requestSort('number_of_trades')}>
                                Number of Trades {getSortIcon('number_of_trades')}
                            </th>
                            <th onClick={() => requestSort('avg_holding_time_min')}>
                                Avg Holding Time (min) {getSortIcon('avg_holding_time_min')}
                            </th>
                            <th onClick={() => requestSort('profitable_trade_ratio')}>
                                Profitable Trade Ratio (%) {getSortIcon('profitable_trade_ratio')}
                            </th>
                            <th onClick={() => requestSort('last_15_days_pnl')}>
                                Last 15 Days PnL {getSortIcon('last_15_days_pnl')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData.map((metric, index) => (
                            <tr key={index}>
                                <td>{metric.Symbol}</td>
                                <td>{formatNumber(metric.turnover)}</td>
                                <td className={metric.PnL >= 0 ? 'positive' : 'negative'}>
                                    {formatNumber(metric.PnL)}
                                </td>
                                <td>{formatNumber(metric.number_of_trades)}</td>
                                <td>{formatNumber(metric.avg_holding_time_min)}</td>
                                <td>{formatNumber(metric.profitable_trade_ratio)}%</td>
                                <td>{formatNumber(metric.last_15_days_pnl)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default SymbolMetricsTable;