import "./../../css/utils/utils.css"

export const formatNumber = (value) => {
    if (typeof value === 'number') {
        return value.toLocaleString('en-US', { maximumFractionDigits: 2 });
    }
    return value;
};

export const formatLargeNumber = (num) => {
    if (typeof num !== 'number') {
        return num; // Return as is if not a number
    }

    const absNum = Math.abs(num);

    if (absNum >= 1000000) {
        return (num / 1000000).toFixed(2) + 'M';
    } else if (absNum >= 1000) {
        return (num / 1000).toFixed(2) + 'K';
    } else {
        return num.toFixed(2);
    }
};


export const ToggleButtonGroup = ({ options, selectedOption, setSelectedOption }) => {
    return (
        <div className="toggle-group">
            {options.map((option) => (
                <button
                    key={option.key}
                    className={`toggle-button ${selectedOption.key === option.key ? 'selected' : ''}`}
                    onClick={() => setSelectedOption(option)}
                >
                    {option.value}
                </button>
            ))}
        </div>
    );
};


export const symbol_dict = { '@1': 'HFUN/USDC', '@2': 'LICK/USDC', '@3': 'MANLET/USDC', '@4': 'JEFF/USDC', '@5': 'SIX/USDC', '@6': 'WAGMI/USDC', '@7': 'CAPPY/USDC', '@8': 'POINTS/USDC', '@9': 'TRUMP/USDC', '@10': 'GMEOW/USDC', '@11': 'PEPE/USDC', '@12': 'XULIAN/USDC', '@13': 'RUG/USDC', '@14': 'ILIENS/USDC', '@15': 'FUCKY/USDC', '@16': 'CZ/USDC', '@17': 'BAGS/USDC', '@18': 'ANSEM/USDC', '@19': 'TATE/USDC', '@20': 'PUMP/USDC', '@21': 'KOBE/USDC', '@22': 'HAPPY/USDC', '@23': 'SCHIZO/USDC', '@24': 'SELL/USDC', '@25': 'BIGBEN/USDC', '@26': 'CATNIP/USDC', '@27': 'HBOOST/USDC', '@28': 'SUCKY/USDC', '@29': 'GUP/USDC', '@30': 'FARMED/USDC', '@31': 'GPT/USDC', '@32': 'PURRPS/USDC', '@33': 'BID/USDC', '@34': 'HODL/USDC', '@35': 'VEGAS/USDC', '@36': 'ASI/USDC', '@37': 'VAPOR/USDC', '@38': 'PANDA/USDC', '@39': 'PILL/USDC', '@40': 'ADHD/USDC', '@41': 'FUN/USDC', '@42': 'LADY/USDC', '@43': 'MOG/USDC', '@44': 'HPEPE/USDC', '@45': 'JEET/USDC', '@46': 'DROP/USDC', '@47': 'MBAPPE/USDC', '@48': 'TEST/USDC', '@49': 'RAGE/USDC', '@50': 'FRAC/USDC', '@51': 'ATEHUN/USDC', '@52': 'COZY/USDC', '@53': 'ARI/USDC', '@54': 'WASH/USDC', '@55': 'ANT/USDC', '@56': 'NFT/USDC', '@57': 'RICH/USDC', '@58': 'LORA/USDC', '@59': 'CATBAL/USDC', '@60': 'TJIF/USDC', '@61': 'GUESS/USDC', '@62': 'MAXI/USDC' }