// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/index.css */

/* Import the Roboto Mono font from Google Fonts */
/* @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;700&display=swap'); */

/* Base styles */
:root {
  --primary-color: #95F7E0;
  --background-color: #0F1A1E;
  --text-color-heading: #949E9C;
  --border-color: #303030;
  --text-color: #FFFFFF;
  /* --app-font-family: 'Roboto Mono', Monaco, monospace; */
  --app-font-family: 'Noto Sans', sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--app-font-family) !important;;
  color: var(--text-color);
  background: #0F1A1E;
  
  /* height: 100vh;  */
  /* 100% of the viewport height */
}

body, input, button, select, .ant-select-selection-item, .ant-select-selection-placeholder {
  font-family: var(--app-font-family) !important;
}

#root {
  background: transparent;
}
/* Additional global styles can go here */`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB,kDAAkD;AAClD,uGAAuG;;AAGvG,gBAAgB;AAChB;EACE,wBAAwB;EACxB,2BAA2B;EAC3B,6BAA6B;EAC7B,uBAAuB;EACvB,qBAAqB;EACrB,yDAAyD;EACzD,0CAA0C;AAC5C;;AAEA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,8CAA8C;EAC9C,wBAAwB;EACxB,mBAAmB;;EAEnB,oBAAoB;EACpB,gCAAgC;AAClC;;AAEA;EACE,8CAA8C;AAChD;;AAEA;EACE,uBAAuB;AACzB;AACA,yCAAyC","sourcesContent":["/* src/index.css */\n\n/* Import the Roboto Mono font from Google Fonts */\n/* @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500;700&display=swap'); */\n@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;700&display=swap');\n\n/* Base styles */\n:root {\n  --primary-color: #95F7E0;\n  --background-color: #0F1A1E;\n  --text-color-heading: #949E9C;\n  --border-color: #303030;\n  --text-color: #FFFFFF;\n  /* --app-font-family: 'Roboto Mono', Monaco, monospace; */\n  --app-font-family: 'Noto Sans', sans-serif;\n}\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\nbody {\n  font-family: var(--app-font-family) !important;;\n  color: var(--text-color);\n  background: #0F1A1E;\n  \n  /* height: 100vh;  */\n  /* 100% of the viewport height */\n}\n\nbody, input, button, select, .ant-select-selection-item, .ant-select-selection-placeholder {\n  font-family: var(--app-font-family) !important;\n}\n\n#root {\n  background: transparent;\n}\n/* Additional global styles can go here */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
