import React, { useState } from 'react';
import { ArrowUpIcon, ArrowDownIcon, TrendingDown, TrendingUp } from 'lucide-react';
import './../../css/dashboard/timeBasedMetrics.css';
import { formatNumber, ToggleButtonGroup } from '../utils/utils';

const TimeBasedMetrics = ({ walletData }) => {
    const data_metrics = walletData.all_metrics;

    const [selectedPnL, setSelectedPnL] = useState({ key: '7d-pnl', value: '7 Days PnL' });

    // Sample data - replace with actual data fetching logic
    const metrics = {
        last_7d_pnl: data_metrics.last_7d_pnl,
        last_30d_pnl: data_metrics.last_30d_pnl,
        lt_pnl: data_metrics.lt_pnl,

        last_trade_time: data_metrics.last_trade_time,
        start_trade_time: data_metrics.start_trade_time
    };

    const calculateDailyAverage = (total, days) => {
        return (total / days).toFixed(2);
    };



    const pnlOptions = [
        { key: '7d-pnl', value: '7 Days PnL' },
        { key: '30d-pnl', value: '30 Days PnL' },
        { key: 'last-10k-tx-pnl', value: 'Last 10K Tx PnL' }
    ]

    const PnlCard = () => (
        <>
            {selectedPnL.key === '7d-pnl' &&
                <div className='time_row_grid'>
                    <div className='pnl_data_col'>
                        <div className='pnl_card'>
                            <div>
                                <div className='pnl_card_heading'>
                                    {/* <span>Last 7 Days PnL</span> */}
                                    <span>P{'&'}L for the 7 Days Leading Up to the Last Trade</span>
                                </div>
                                <br />
                                <div className='pnl_card_content'>
                                    <p className="pnl_text">${formatNumber(metrics.last_7d_pnl)}</p>
                                    <p className="pnl_sub_text">
                                        Daily Avg: ${formatNumber(calculateDailyAverage(metrics.last_7d_pnl, 7))}
                                    </p>
                                </div>
                            </div>
                            <div className='pnLSymbol'>
                                {metrics.last_7d_pnl >= 0 ? (
                                    <ArrowUpIcon size={32} width={32} height={32} color='green' />
                                ) : (
                                    <ArrowDownIcon size={32} width={32} height={32} color='red' />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            }
            {selectedPnL.key === '30d-pnl' &&
                <div className='time_row_grid'>
                    <div className='pnl_data_col'>
                        <div className='pnl_card'>
                            <div>
                                <div className='pnl_card_heading'>
                                    {/* <span>Last 30 Days PnL</span> */}
                                    <span>P{'&'}L for the 30 Days Leading Up to the Last Trade</span>
                                </div>
                                <br />
                                <div className='pnl_card_content'>
                                    <p className="pnl_text">${formatNumber(metrics.last_30d_pnl)}</p>
                                    <p className="pnl_sub_text">
                                        Daily Avg: ${formatNumber(calculateDailyAverage(metrics.last_30d_pnl, 30))}
                                    </p>
                                </div>
                            </div>
                            <div className='pnLSymbol'>
                                {metrics.last_30d_pnl >= 0 ? (
                                    <ArrowUpIcon size={32} width={32} height={32} color='green' />
                                ) : (
                                    <ArrowDownIcon size={32} width={32} height={32} color='red' />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            }
            {selectedPnL.key === 'last-10k-tx-pnl' &&
                <div className='time_row_grid'>
                    <div className='pnl_data_col'>
                        <div className='pnl_card'>
                            <div>
                                <div className='pnl_card_heading'>
                                    {/* <span>Last 30 Days PnL</span> */}
                                    <span>P{'&'}L for the last 10k Tx Leading Up to the Last Trade</span>
                                </div>
                                <br />
                                <div className='pnl_card_content'>
                                    <p className="pnl_text">${formatNumber(metrics.lt_pnl)}</p>
                                    <p className="pnl_sub_text">
                                        <br />
                                        {/* Daily Avg: ${formatNumber(calculateDailyAverage(metrics.lt_pnl, 30))} */}
                                        {" "}
                                    </p>
                                </div>
                            </div>
                            <div className='pnLSymbol'>
                                {metrics.lt_pnl >= 0 ? (
                                    <TrendingUp size={32} width={32} height={32} color='green' />
                                ) : (
                                    <TrendingDown size={32} width={32} height={32} color='red' />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )


    return (
        <>
            <div className="">
                <div>
                    <ToggleButtonGroup
                        options={pnlOptions}
                        selectedOption={selectedPnL}
                        setSelectedOption={setSelectedPnL}
                    />
                    <PnlCard />
                </div>
            </div>
        </>
    );
};

export default TimeBasedMetrics;