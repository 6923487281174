import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Portfolio from './components/dashboard/portfolio';
import AllMetricsTable from './components/all_metrics/allMetricsTable';
import SymbolWiseMetricsTable from './components/all_metrics/symbol_wise_metrics_table';
import VaultWiseMetricsTable from './components/all_metrics/vault_wise_metrics';
import Navbar from './components/navbar/navbar';
import Footer from './components/footer/footer';
import "./App.css"

const App = () => {
  return (
    <Router>
      <Navbar />
      <div className="main2">
        <Routes>
          <Route path="/all_metrics" element={<AllMetricsTable />} />
          <Route path="/symbol_wise_metrics" element={<SymbolWiseMetricsTable />} />
          <Route path="/vault_metrics" element={<VaultWiseMetricsTable />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/" element={<Navigate replace to="/all_metrics" />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
};

export default App;