import React, { useState, useMemo } from 'react';
import { ArrowUp, ArrowDown, ArrowUpDown } from 'lucide-react';
import "./../../css/dashboard/symbolMetricsTable.css";
import { formatNumber, symbol_dict } from '../utils/utils';

const SpotBalanceTable = ({ spotBalance_metrics }) => {
    const [sortConfig, setSortConfig] = useState({ key: 'balance', direction: 'descending' });

    let balancesArray = useMemo(() => {
        return Object.entries(spotBalance_metrics).map(([coin, balance]) => ({
            coin,
            balance
        }));
    }, [spotBalance_metrics]);

    balancesArray = balancesArray.map(item => ({
        ...item,
        coin: symbol_dict[item.coin] ? symbol_dict[item.coin].split("/")[0] : item.coin.split("/")[0]
    }));

    const sortedData = useMemo(() => {
        let sortableItems = [...balancesArray];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [balancesArray, sortConfig]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = (columnName) => {
        if (sortConfig.key === columnName) {
            return sortConfig.direction === 'ascending' ?
                <ArrowUp className="sort-icon" size={16} /> :
                <ArrowDown className="sort-icon" size={16} />;
        }
        return <ArrowUpDown className="sort-icon" size={16} />;
    };

    return (
        <div className="symbol-metrics-table">
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th onClick={() => requestSort('coin')}>
                                Coin {getSortIcon('coin')}
                            </th>
                            <th onClick={() => requestSort('balance')}>
                                Balance {getSortIcon('balance')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData.map((metric, index) => (
                            <tr key={index}>
                                <td>{metric.coin}</td>
                                <td>{formatNumber(metric.balance)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default SpotBalanceTable;