import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { Select, Checkbox, Space, Tooltip } from 'antd';
import { FilterFilled, CopyOutlined, LinkOutlined, DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import CountUp from 'react-countup';
import { CSVLink } from "react-csv";
import {
    StyledSelect,
    StyledTable,
    ModernInput,
    ButtonGroup,
    ModernButton,
    ModernSelect,
    ModernDatePicker
} from './all_metrics_styled_componenet'
import "./../../css/all_metrics/all_metrics.css"

import { formatNumber, symbol_dict, formatLargeNumber } from '../utils/utils';

import vaultMetricsCSV from './../../Data_csv/vault_final.csv';


const { Option } = Select;

const headerMapping = {
    "wallet": "Wallet Address",
    "hold_time(min)": "Hold Time (min)",
    "pct_long_trade": "Long Trades (%)",
    "pct_prof_trade": "Profitable Trades (%)",
    "avg_ret_trade_bps": "Avg. Return per Trade (bps)",
    "total_trades": "Total Trades",
    "pct_long_prof_trade": "Profitable Long Trades (%)",
    "pct_short_prof_trade": "Profitable Short Trades (%)",
    "max_dd": "Max Drawdown",
    "last_7d_pnl": "7-Day PnL",
    "last_30d_pnl": "30-Day PnL",
    "lt_pnl": "Last 10K Tx PnL",
    "most_prof_symbol": "Most Profitable Symbol",
    "most_prof_symbol_abs_pnl": "Most Profitable Symbol PnL",
    "total_turnover": "Total Turnover",
    "mtm/tv": "MTM/TV Ratio",
    "last_trade_time": "Last Trade Time",
    "start_trade_time": "First Trade Time",
    "fee/turnover": "Fee/Turnover Ratio",
    "avg_daily_trade": "Avg. Daily Trades",
    "total_symbols_traded": "Total Symbols Traded",
    "name": "Name",
    "leader": "Leader",
    "tvl": "Total Value Locked",
    "createTimeMillis": "Creation Time (ms)"
};

// Array of objects with key-label pairs
const headerMappingArray = [
    { key: "wallet", label: "Wallet Address" },
    { key: "hold_time(min)", label: "Hold Time (min)" },
    { key: "pct_long_trade", label: "Long Trades (%)" },
    { key: "pct_prof_trade", label: "Profitable Trades (%)" },
    { key: "avg_ret_trade_bps", label: "Avg. Return per Trade (bps)" },
    { key: "total_trades", label: "Total Trades" },
    { key: "pct_long_prof_trade", label: "Profitable Long Trades (%)" },
    { key: "pct_short_prof_trade", label: "Profitable Short Trades (%)" },
    { key: "max_dd", label: "Max Drawdown" },
    { key: "last_7d_pnl", label: "7-Day PnL" },
    { key: "last_30d_pnl", label: "30-Day PnL" },
    { key: "lt_pnl", label: "Last 10K Tx PnL" },
    { key: "most_prof_symbol", label: "Most Profitable Symbol" },
    { key: "most_prof_symbol_abs_pnl", label: "Most Profitable Symbol PnL" },
    { key: "total_turnover", label: "Total Turnover" },
    { key: "mtm/tv", label: "MTM/TV Ratio" },
    { key: "last_trade_time", label: "Last Trade Time" },
    { key: "start_trade_time", label: "First Trade Time" },
    { key: "fee/turnover", label: "Fee/Turnover Ratio" },
    { key: "avg_daily_trade", label: "Avg. Daily Trades" },
    { key: "total_symbols_traded", label: "Total Symbols Traded" },
    { key: "name", label: "Name" },
    { key: "leader", label: "Leader" },
    { key: "tvl", label: "Total Value Locked" },
    { key: "createTimeMillis", label: "Creation Time (ms)" }
];

// Define default columns to show
const defaultColumns = [
    'name',
    'wallet',
    // 'leader',
    'tvl',
    'avg_ret_trade_bps',
    'hold_time(min)',
    'pct_prof_trade',
    'lt_pnl',
    'last_30d_pnl',
    'total_trades',
    'total_turnover',
    'total_symbols_traded'
];


// const DataFormat = "DD/MM/YY HH:mm"
const DataFormat = "YYYY/MM/DD HH:mm:ss"


const VaultWiseMetricsTable = () => {
    const [data, setData] = useState([]);
    const [showingData, setShowingData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [columnsOption, setColumnsOption] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // const [symbols, setSymbols] = useState([]);
    const [walletColumn, setWalletColumn] = useState(null);
    const [nameColumn, setNameColumn] = useState(null);


    useEffect(() => {
        const fetchCSV = async () => {
            try {
                /* version 1 */
                // const response = await fetch(vaultMetricsCSV);
                // const reader = response.body.getReader();
                // const result = await reader.read();
                // const decoder = new TextDecoder('utf-8');
                // const csv = decoder.decode(result.value);

                /* version 2 */
                const response = await fetch(vaultMetricsCSV);
                const reader = response.body.getReader();
                const decoder = new TextDecoder('utf-8');
                let csv = '';

                while (true) {
                    const { done, value } = await reader.read();
                    if (done) break;
                    csv += decoder.decode(value, { stream: true });
                }

                // const text = await response.text();

                // console.log("ass", response)
                // console.log("reader", reader)
                // // console.log("result", result)
                // console.log("text", csv)

                Papa.parse(csv, {
                    header: true,
                    // dynamicTyping: true,
                    skipEmptyLines: true, // Skip empty lines
                    dynamicTyping: (header) => header !== 'name', // Prevent dynamic typing for 'name' column
                    complete: (results) => {
                        // const parsedData = results.data;
                        const parsedData = results.data.map(row => ({
                            ...row,
                            name: String(row.name), // Ensure 'name' is always a string
                            last_trade_time: moment(row.last_trade_time, DataFormat).toDate(),
                            start_trade_time: moment(row.start_trade_time, DataFormat).toDate()
                        }));

                        const data_refactor = parsedData.map(item => ({
                            ...item,
                            most_prof_symbol: symbol_dict[item.most_prof_symbol] ? symbol_dict[item.most_prof_symbol].split("/")[0] : item.most_prof_symbol.split("/")[0]
                        }));

                        setData(data_refactor);
                        setShowingData(data_refactor)

                        // Extract unique symbols
                        const uniqueSymbols = [...new Set(data_refactor.map(item => item.most_prof_symbol))].filter(Boolean);
                        // setSymbols(uniqueSymbols);

                        const csvColumns = Object.keys(parsedData[0])
                            .map((key) => {
                                let column = {
                                    title: headerMapping[key] || key,
                                    dataIndex: key,
                                    key: key,
                                    align: 'center',
                                    width: 170,
                                    fixed: key === 'name' ? 'left' : false,
                                    sorter: (a, b) => {
                                        console.log("aaaa", a[key], typeof (a[key]))
                                        if (typeof a[key] === 'number' && typeof b[key] === 'number') {
                                            return a[key] - b[key];
                                        } else if (key === 'last_trade_time' || key === 'start_trade_time') {
                                            // return moment(a[key]).unix() - moment(b[key]).unix();
                                            return a[key] - b[key];
                                        } else {
                                            return (a[key] || '').localeCompare(b[key] || '');
                                        }
                                    },
                                    sortDirections: ['descend', 'ascend'],
                                    render: (text, record) => {
                                        if (key === 'wallet') {
                                            return (
                                                <Space>
                                                    <Tooltip title={text}>
                                                        {shortenAddress(text)}
                                                    </Tooltip>
                                                    <Tooltip title="Copy to clipboard">
                                                        <CopyOutlined onClick={() => copyToClipboard(text)} style={{ cursor: 'pointer' }} />
                                                    </Tooltip>
                                                    <Tooltip title="View portfolio">
                                                        <LinkOutlined onClick={() => window.open(`/portfolio/?address=${text}`, '_blank')} style={{ cursor: 'pointer' }} />
                                                    </Tooltip>
                                                </Space>
                                            );
                                        }

                                        if (key === 'name') {
                                            return (
                                                <Space>
                                                    {text}
                                                    <Tooltip title="Copy to clipboard">
                                                        <CopyOutlined onClick={() => copyToClipboard(text)} style={{ cursor: 'pointer' }} />
                                                    </Tooltip>
                                                </Space>
                                            );
                                        }
                                        if (key === 'most_prof_symbol') {
                                            return text; // Return most_prof_symbol as-is
                                        }

                                        if (key === 'leader') {
                                            return (
                                                <Space>
                                                    <Tooltip title={text}>
                                                        {shortenAddress(text)}
                                                    </Tooltip>
                                                    <Tooltip title="Copy to clipboard">
                                                        <CopyOutlined onClick={() => copyToClipboard(text)} style={{ cursor: 'pointer' }} />
                                                    </Tooltip>
                                                </Space>
                                            );
                                        }

                                        if (key === 'createTimeMillis') {
                                            return text; // Return most_prof_symbol as-is
                                        }

                                        if (key === 'last_trade_time' || key === 'start_trade_time') {
                                            return moment(text).format(DataFormat);
                                            // return text; // Return most_prof_symbol as-is
                                        }
                                        const value = parseFloat(text);
                                        if (!isNaN(value)) {
                                            if (key === 'max_dd' || key === 'last_7d_pnl' || key === 'last_30d_pnl' || key === 'lt_pnl' || key === 'most_prof_symbol_abs_pnl' || key === 'total_turnover' || key === "tvl") {
                                                return formatLargeNumber(value)
                                            }
                                            // return value.toFixed(2);
                                            return formatNumber(value)
                                        }
                                        return text;
                                    },
                                };

                                // // Custom filter for wallet address
                                // if (key === 'wallet') {
                                //     column.filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                                //         <WalletFilterDropdown
                                //             setSelectedKeys={setSelectedKeys}
                                //             selectedKeys={selectedKeys}
                                //             confirm={confirm}
                                //             clearFilters={clearFilters}
                                //         />
                                //     );
                                //     column.onFilter = (value, record) => record.wallet.includes(value);
                                // }
                                if (key === 'wallet' || key === 'name' || key === 'leader') {
                                    column.filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                                        <TextFilterDropdown
                                            setSelectedKeys={setSelectedKeys}
                                            selectedKeys={selectedKeys}
                                            confirm={confirm}
                                            clearFilters={clearFilters}
                                            placeholder={`Enter ${key}`}
                                        />
                                    );

                                    if (key === 'name') {
                                        column.onFilter = (value, record) => {
                                            return record.name.toString().toLowerCase().includes(value.toString().toLowerCase());
                                        };
                                    }

                                    column.onFilter = (value, record) => {
                                        console.log("SSSss", value, record[key], key, typeof (value), typeof (record[key]), typeof (key))
                                        return record[key].toLowerCase().includes(value.toLowerCase());
                                    }
                                }

                                // Custom filter for most profitable symbol
                                else if (key === 'most_prof_symbol') {
                                    column.filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                                        <SymbolFilterDropdown
                                            symbols={uniqueSymbols}
                                            setSelectedKeys={setSelectedKeys}
                                            selectedKeys={selectedKeys}
                                            confirm={confirm}
                                            clearFilters={clearFilters}
                                        />
                                    );
                                    column.onFilter = (value, record) => record.most_prof_symbol === value;
                                }

                                // Custom filter for date columns
                                else if (key === 'start_trade_time' || key === 'last_trade_time') {
                                    column.filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                                        <DateFilterDropdown
                                            setSelectedKeys={setSelectedKeys}
                                            selectedKeys={selectedKeys}
                                            confirm={confirm}
                                            clearFilters={clearFilters}
                                        />
                                    );
                                    column.onFilter = (value, record) => {
                                        const [comparison, dateString] = value.split(',');
                                        const recordDate = moment(record[key]);
                                        // const filterDate = moment(dateString);
                                        const filterDate = moment(dateString, "YYYY-MM-DD HH:mm:ss");

                                        switch (comparison) {
                                            case 'before':
                                                return recordDate.isBefore(filterDate);
                                            case 'after':
                                                return recordDate.isAfter(filterDate);
                                            case 'on':
                                                return recordDate.isSame(filterDate, 'day');
                                            default:
                                                return true;
                                        }
                                    };
                                }

                                // Default numeric filter for other columns
                                else {
                                    column.filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                                        <NumericFilterDropdown
                                            setSelectedKeys={setSelectedKeys}
                                            selectedKeys={selectedKeys}
                                            confirm={confirm}
                                            clearFilters={clearFilters}
                                        />
                                    );
                                    column.onFilter = (value, record) => {
                                        const [comparison, filterValue] = value.split(',');
                                        const recordValue = parseFloat(record[key]);
                                        const filterNumber = parseFloat(filterValue);

                                        switch (comparison) {
                                            case 'gt':
                                                return recordValue > filterNumber;
                                            case 'lt':
                                                return recordValue < filterNumber;
                                            case 'eq':
                                                return recordValue === filterNumber;
                                            case 'lte':
                                                return recordValue <= filterNumber;
                                            default:
                                                return true;
                                        }
                                    };
                                }

                                column.filterIcon = filtered => <FilterFilled style={{ color: filtered ? '#1890ff' : undefined }} />;

                                return column;
                            });
                        const walletCol = csvColumns.find(col => col.key === 'wallet');
                        const nameCol = csvColumns.find(col => col.key === 'name');
                        // const otherCols = csvColumns.filter(col => col.key !== 'wallet');
                        const otherCols = csvColumns.filter(col => col.key !== 'wallet' && col.key !== 'name');

                        setWalletColumn(walletCol);
                        setNameColumn(nameCol);
                        setColumnsOption(otherCols)
                        setColumns(csvColumns);
                        // setSelectedColumns(otherCols.map(col => col.key));

                        // Set selected columns to default columns
                        setSelectedColumns(defaultColumns);
                    },
                    error: (error) => {
                        setError('Error parsing CSV: ' + error.message);
                    }
                });
            } catch (error) {
                setError('Error reading CSV file: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchCSV();
    }, []);

    const shortenAddress = (address) => {
        return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`;
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            // You can add a notification here if you want to inform the user that the text was copied
            console.log('Copied to clipboard');
        });
    };

    const handleColumnChange = (selectedKeys) => {
        setSelectedColumns(selectedKeys);
    };

    const visibleColumns = walletColumn && nameColumn
        ? [walletColumn, nameColumn, ...columnsOption.filter(col => selectedColumns.includes(col.key))]
        : columns.filter(col => selectedColumns.includes(col.key));


    // // Modify the visibleColumns logic
    // const visibleColumns = [
    //     walletColumn,

    //     columns.find(col => col.key === 'name'),
    //     ...columns.filter(col => selectedColumns.includes(col.key) && col.key !== 'name')
    // ].filter(Boolean);


    // Sort the visible columns according to the defaultColumns order
    visibleColumns.sort((a, b) => {
        const indexA = defaultColumns.indexOf(a.key);
        const indexB = defaultColumns.indexOf(b.key);
        if (indexA === -1 && indexB === -1) return 0;
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;
        return indexA - indexB;
    });

    const handleTableChange = (pagination, filters, sorter) => {
        console.log('Table parameters:', pagination, filters, sorter);

        console.log('data', data.length
        );


        // Update the data based on the current filters
        let filteredData = [...data];
        Object.keys(filters).forEach(key => {
            const filterValues = filters[key];
            if (filterValues && filterValues.length > 0) {
                filteredData = filteredData.filter(item => {
                    return columns.find(col => col.key === key).onFilter(filterValues[0], item);
                });
            }
        });

        // setData(filteredData);
        setShowingData(filteredData)
    };

    // const dropdownRender = (menu) => (
    //     <div style={{ maxHeight: '240px', overflowY: 'auto' }}>
    //         {columns.map(col => (
    //             <div key={col.key} style={{ padding: '8px 12px', borderBottom: '1px solid #303030' }}>
    //                 <Checkbox
    //                     checked={selectedColumns.includes(col.key)}
    //                     onChange={(e) => {
    //                         const newSelectedColumns = e.target.checked
    //                             ? [...selectedColumns, col.key]
    //                             : selectedColumns.filter(key => key !== col.key);
    //                         setSelectedColumns(newSelectedColumns);
    //                     }}
    //                     style={{ width: '100%', color: '#747E7D' }}
    //                 >
    //                     {col.title}
    //                 </Checkbox>
    //             </div>
    //         ))}
    //     </div>
    // );

    if (loading) {
        return <div>Loading data...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    // console.log("symbols", symbols)
    // console.log("columns", columns)
    // console.log("selectedColumns", selectedColumns)
    // console.log("loading", loading)
    // console.log("error", error)

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + "/" + dd + "/" + yyyy;



    return (
        <>
            <div className="dashBoard_head">
                {/* <h1 className="dashBoard_heading">Hyperliquid All Wallet Vault Wise Metrics</h1> */}
                <div className="wallet_note">
                    <h4>
                        We show only realized PnL with assumption of 2 bps fee per trade. Analytics are based on the last 10,000 transactions or all if fewer.
                    </h4>
                </div>
            </div>
            <br />
            <div className="all_metrics_main_grid">
                <div className='all_metrics_main_card all_metrics_main_card_content'>
                    <div className="all_metrics_content_heading">Total Entries</div>
                    <div className="all_metrics_content_value"><CountUp end={data.length} duration={2.5} formattingFn={(value) => formatNumber(value)} /></div>
                </div>
                <div className='all_metrics_main_card all_metrics_main_card_content'>
                    <div className="all_metrics_content_heading">Total Entries Shown</div>
                    <div className="all_metrics_content_value"><CountUp end={showingData.length} duration={2.5} formattingFn={(value) => formatNumber(value)} /></div>
                </div>
                <div className='all_metrics_main_card all_metrics_main_card_content'>
                    {/* <div className="all_metrics_content_heading">Select columns here which you want to see.</div> */}
                    <div className="all_metrics_content_heading">Select additional columns to display</div>
                    <StyledSelect
                        mode="multiple"
                        placeholder="Select columns to display"
                        value={selectedColumns}
                        onChange={handleColumnChange}
                        dropdownRender={menu => (
                            <div style={{ maxHeight: '240px', overflowY: 'auto' }}>
                                {columnsOption.map(col => (
                                    <div key={col.key} style={{ padding: '8px 12px', borderBottom: '1px solid grey' }}>
                                        <Checkbox
                                            checked={selectedColumns.includes(col.key)}
                                            onChange={(e) => {
                                                const newSelectedColumns = e.target.checked
                                                    ? [...selectedColumns, col.key]
                                                    : selectedColumns.filter(key => key !== col.key);
                                                setSelectedColumns(newSelectedColumns);
                                            }}
                                            style={{ width: '100%', color: '#747E7D' }}
                                        >
                                            {col.title}
                                        </Checkbox>
                                    </div>
                                ))}
                            </div>
                        )}
                        maxTagCount={0}
                        maxTagPlaceholder={() => `${selectedColumns.length} out of ${columnsOption.length + 2} columns selected`}
                    />
                </div>
                <div className='all_metrics_main_card all_metrics_main_card_content'>
                    <div className="all_metrics_content_heading">Export to CSV</div>
                    <div className="all_metrics_content_value">
                        <CSVLink
                            data={showingData}
                            headers={headerMappingArray}
                            filename={`Hyperliquid_all_metics_${today}.csv`}
                        >
                            {/* <Button type="primary" icon={<DownloadOutlined />}>
                Export to CSV
              </Button> */}
                            <DownloadOutlined style={{ cursor: 'pointer', color: "white", fontSize: "43px" }} />
                        </CSVLink>
                    </div>
                </div>
            </div >
            <div className="table_note">
                Please scroll right to see all columns
            </div>
            <StyledTable
                columns={visibleColumns}
                dataSource={data}
                tableLayout='fixed'
                scroll={{ x: '100vw', y: '70vh' }}
                // scroll={{ x: true }}
                // scroll={{ y: 300 }}
                // tableLayout={'fixed'}]
                onChange={handleTableChange}
            />
        </>
    );
};


// const WalletFilterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
//     const [inputValue, setInputValue] = useState('');

//     const handleConfirm = () => {
//         if (inputValue) {
//             setSelectedKeys([inputValue]);
//             confirm();
//         }
//     };

//     const handleReset = () => {
//         clearFilters();
//         setInputValue('');
//         setSelectedKeys([]);
//         confirm(); // Add this line to apply the reset
//     };

//     return (
//         <div style={{ padding: 8, backgroundColor: '#212428', border: '1px solid #303030' }}>
//             <ModernInput
//                 placeholder="Enter wallet address"
//                 value={inputValue}
//                 onChange={e => setInputValue(e.target.value)}
//                 onPressEnter={handleConfirm}
//                 style={{ width: '100%', marginBottom: 8 }}
//             />
//             <ButtonGroup>
//                 <ModernButton onClick={handleConfirm}>
//                     OK
//                 </ModernButton>
//                 <ModernButton type="warning" onClick={handleReset}>
//                     Reset
//                 </ModernButton>
//             </ButtonGroup>
//         </div>
//     );
// };

const TextFilterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, placeholder }) => {
    const [inputValue, setInputValue] = useState('');

    const handleConfirm = () => {
        if (inputValue) {
            setSelectedKeys([inputValue]);
            confirm();
        }
    };

    const handleReset = () => {
        clearFilters();
        setInputValue('');
        setSelectedKeys([]);
        confirm();
    };

    return (
        <div style={{ padding: 8, backgroundColor: '#212428', border: '1px solid #303030' }}>
            <ModernInput
                placeholder={placeholder}
                value={inputValue}
                onChange={e => setInputValue(e.target.value)}
                onPressEnter={handleConfirm}
                style={{ width: '100%', marginBottom: 8 }}
            />
            <ButtonGroup>
                <ModernButton onClick={handleConfirm}>
                    OK
                </ModernButton>
                <ModernButton type="warning" onClick={handleReset}>
                    Reset
                </ModernButton>
            </ButtonGroup>
        </div>
    );
};


const SymbolFilterDropdown = ({ symbols, setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
    const handleReset = () => {
        clearFilters();
        setSelectedKeys([]);
        confirm(); // Add this line to apply the reset
    };

    return (
        <div style={{ padding: 8, backgroundColor: '#212428', border: '1px solid #303030' }}>
            <ModernSelect
                style={{ width: '100%', marginBottom: 8 }}
                placeholder="Select symbol"
                value={selectedKeys[0]}
                onChange={(value) => setSelectedKeys(value ? [value] : [])}
            >
                {symbols.map(symbol => (
                    <Option key={symbol} value={symbol}>{symbol}</Option>
                ))}
            </ModernSelect>
            <ButtonGroup>
                <ModernButton onClick={confirm}>
                    OK
                </ModernButton>
                <ModernButton type="warning" onClick={handleReset}>
                    Reset
                </ModernButton>
            </ButtonGroup>
        </div>
    );
};

const DateFilterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
    const [comparison, setComparison] = useState('before');
    const [dateTime, setDateTime] = useState(null);

    const handleFilter = () => {
        if (dateTime) {
            setSelectedKeys([`${comparison},${dateTime.format('YYYY-MM-DD HH:mm:ss')}`]);
            confirm();
        }
    };

    const handleReset = () => {
        clearFilters();
        setComparison('before');
        setDateTime(null);
        setSelectedKeys([]);
        confirm(); // Add this line to apply the reset
    };

    return (
        <div style={{ padding: 8, backgroundColor: '#212428', border: '1px solid #303030' }}>
            <Space direction="vertical" size={8} style={{ width: '100%' }}>
                <ModernSelect value={comparison} onChange={setComparison} style={{ width: '100%' }}>
                    <Option value="before">Before</Option>
                    <Option value="after">After</Option>
                    <Option value="on">On</Option>
                </ModernSelect>
                <ModernDatePicker
                    showTime
                    value={dateTime}
                    onChange={setDateTime}
                    style={{ width: '100%' }}
                    format={DataFormat}
                />
                <ButtonGroup>
                    <ModernButton onClick={handleFilter}>
                        OK
                    </ModernButton>
                    <ModernButton type="warning" onClick={handleReset}>
                        Reset
                    </ModernButton>
                </ButtonGroup>
            </Space>
        </div>
    );
};

const NumericFilterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
    const [comparison, setComparison] = useState('gt');
    const [value, setValue] = useState('');

    const handleFilter = () => {
        if (value) {
            setSelectedKeys([`${comparison},${value}`]);
            confirm();
        }
    };

    const handleReset = () => {

        console.log("Sssdskbjqwdkskj", selectedKeys)
        clearFilters();
        setComparison('gt');
        setValue('');
        setSelectedKeys([]);
        confirm(); // Add this line to apply the reset
    };

    return (
        <div style={{ padding: 8, backgroundColor: '#212428', border: '1px solid #303030' }}>
            <Space direction="vertical" size={8} style={{ width: '100%' }}>
                <ModernSelect value={comparison} onChange={setComparison} style={{ width: '100%' }}>
                    <Option value="gt">Greater than</Option>
                    <Option value="lt">Less than</Option>
                    <Option value="eq">Equal to</Option>
                    <Option value="lte">Less than or equal to</Option>
                </ModernSelect>
                <ModernInput
                    placeholder="Enter value"
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    style={{ width: '100%' }}
                />
                <ButtonGroup>
                    <ModernButton onClick={handleFilter}>
                        OK
                    </ModernButton>
                    <ModernButton type="warning" onClick={handleReset}>
                        Reset
                    </ModernButton>
                </ButtonGroup>
            </Space>
        </div>
    );
};

export default VaultWiseMetricsTable;

