import React from 'react';
import {
    TrendingUp,
    TrendingDown,
    // DollarSign, Hash
} from 'lucide-react';
import { formatNumber } from '../utils/utils';
import "./../../css/dashboard/balanceMetrics.css"

const MetricCard = ({ title, value, icon: Icon, prefix, suffix, trend = null }) => {
    const trendColor = trend === 'up' ? 'green' : 'red';
    const TrendIcon = trend === 'up' ? TrendingUp : TrendingDown;

    return (
        <div className="balance_metrics_sub_card">
            <div className="balance_metrics_sub_card_heading">
                <div className="text-sm font-medium">{title}</div>
                {Icon && <Icon size={20} />}
            </div>

            <div className="balance_metrics_sub_card_content">
                {prefix}{value} {suffix}

                {trend ? <TrendIcon color={trendColor} size={32} /> : ""}
            </div>
        </div>
    );
};



const BalanceMetrics = ({ walletData }) => {
    const data_metrics = walletData.balances

    const metrics = {
        spot_usdc_balance: data_metrics.spot_balances.USDC ? data_metrics.spot_balances.USDC : 0,
        spot_purr_balance: data_metrics.spot_balances.PURR ? data_metrics.spot_balances.PURR : 0,
        perp_usdc_balance: data_metrics.perpetual_balance,
        total_position_value: data_metrics.total_position_value,
        total_unrealized_pnl: data_metrics.total_unrealized_pnl
    }


    return (
        <>
            <div className="balance_metrics_main_card">
                <MetricCard
                    title="Spot USDC Balance"
                    value={formatNumber(metrics.spot_usdc_balance)}
                // icon={DollarSign}
                // prefix={'$'}
                // suffix=" USDC"
                // trend={metrics.avg_ret_trade_bps > 0 ? 'up' : 'down'}
                // trend=''
                />

                <MetricCard
                    title="Spot PURR Balance"
                    value={formatNumber(metrics.spot_purr_balance)}
                // icon={Hash}
                // suffix=" PURR"
                // trend={metrics.avg_ret_trade_bps > 0 ? 'up' : 'down'}
                // trend=''
                />

                <MetricCard
                    title="Perp USDC Balance"
                    value={formatNumber(metrics.perp_usdc_balance)}
                    prefix={'$'}
                // icon={DollarSign}
                // suffix=" USDC"
                // trend={metrics.avg_ret_trade_bps > 0 ? 'up' : 'down'}
                // trend=''
                />

                <MetricCard
                    title="Total Position Value"
                    value={formatNumber(metrics.total_position_value)}
                    prefix={'$'}
                // icon={DollarSign}
                // suffix="$"
                // trend={metrics.avg_ret_trade_bps > 0 ? 'up' : 'down'}
                // trend=''
                />

                <MetricCard
                    title="Total Unrealized PnL"
                    value={formatNumber(metrics.total_unrealized_pnl)}
                    prefix={'$'}
                // icon={DollarSign}
                // suffix="$"
                // trend={metrics.total_unrealized_pnl > 0 ? 'up' : 'down'}
                // trend=''
                />

            </div>
        </>
    );
};

export default BalanceMetrics;