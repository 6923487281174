import React, { useMemo } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import './../../css/dashboard/topMetrics.css';

import { symbol_dict } from '../utils/utils';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

const SymbolTradeChartByTrade = ({ symbol_wise_metrics }) => {

    symbol_wise_metrics = symbol_wise_metrics.map(item => ({
        ...item,
        Symbol: symbol_dict[item.Symbol] ? symbol_dict[item.Symbol].split("/")[0] : item.Symbol.split("/")[0]
    }));
    const chartData = useMemo(() => {
        // Sort the data by number_of_trades in descending order
        const sortedData = [...symbol_wise_metrics].sort((a, b) => b.number_of_trades - a.number_of_trades);

        let processedData;
        if (sortedData.length <= 4) {
            processedData = sortedData.map(item => ({
                name: item.Symbol,
                value: item.number_of_trades
            }));
        } else {
            const topFour = sortedData.slice(0, 4);
            const others = sortedData.slice(4);
            const othersnumber_of_trades = others.reduce((sum, item) => sum + item.number_of_trades, 0);

            processedData = [
                ...topFour.map(item => ({ name: item.Symbol, value: item.number_of_trades })),
                { name: 'Others', value: othersnumber_of_trades }
            ];
        }

        return processedData;
    }, [symbol_wise_metrics]);

    return (
        <div className='chart_card'>
            <h3 style={{ textAlign: "center" }}>Symbol Trade Frequency</h3>
            <ResponsiveContainer width="100%" height={250}>
                <PieChart>
                    <Pie
                        data={chartData}
                        cx="55%"
                        cy="50%"
                        labelLine={true}
                        outerRadius={90}
                        innerRadius={70}
                        fill="#8884d8"
                        dataKey="value"
                    // label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                    >
                        {chartData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    {/* <Tooltip formatter={(value) => `${value.toFixed(2)}`} /> */}
                    <Tooltip formatter={(value) => `${value.toLocaleString('en-US', { maximumFractionDigits: 0 })}`} />
                    <Legend layout="horizontal" align="center" verticalAlign="bottom" />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};

export default SymbolTradeChartByTrade;