import styled from 'styled-components';
import { Select, Table, Input, Button, DatePicker } from 'antd';

export const StyledSelect = styled(Select)`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;

  && {
    font-family: var(--app-font-family);
  }

  .ant-select-selector, 
  .ant-select-selection-item, 
  .ant-select-selection-placeholder,
  .ant-select-dropdown,
  .ant-select-item {
    font-family: var(--app-font-family) !important;
  }

  .ant-select {
    color: white !important;
  }

  .ant-select-selector {
    background-color: #0F1A1E !important;
    border-color: grey !important;
    height: 40px !important;
    padding: 4px 11px !important;
    font-family: var(--app-font-family);
  }

  .ant-select-selection-placeholder {
    color: #949E9C !important;
    line-height: 32px !important;
  }

  .ant-select-arrow {
    color: white;
  }

  &:hover .ant-select-arrow {
    color: #949E9C;
  }

  .ant-select-dropdown {
    background-color: #212428 !important;
    font-family: var(--app-font-family);
  }

  && .ant-select-dropdown,
  &&& .ant-select-dropdown,
  .ant-select-dropdown.ant-slide-up-enter,
  .ant-select-dropdown.ant-slide-up-enter-active,
  .ant-select-dropdown.ant-slide-up-leave,
  .ant-select-dropdown.ant-slide-up-leave-active {
    background-color: #212428 !important;
    border: 1px solid #303030 !important;
    max-height: 240px !important;
    overflow-y: auto !important;
    padding: 0 !important;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
  }

  && .ant-select-item {
    color: white !important;
    border-bottom: 1px solid #303030 !important;
    padding: 8px 12px !important;
    background-color: #212428 !important;

    &:hover {
      background-color: #95F7E0 !important;
      color: black !important;
    }

    &:last-child {
      border-bottom: none !important;
    }
  }

  && .ant-select-item-option-selected {
    background-color: #303030 !important;
    color: white !important;
  }

  && .ant-checkbox-wrapper {
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
  }

  && .ant-checkbox {
    .ant-checkbox-inner {
      background-color: #212428 !important;
      border-color: #303030 !important;
    }

    &.ant-checkbox-checked .ant-checkbox-inner {
      background-color: #212428 !important;
      border-color: #303030 !important;
      &::after {
        border-color: #949E9C !important;
      }
    }
  }

  && .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  && .ant-checkbox:hover .ant-checkbox-inner,
  && .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #949E9C !important;
  }

  .ant-select-selection-item {
    color: white !important;
    font-family: var(--app-font-family);
  }
`;

export const StyledTable = styled(Table)`
  && {
    font-family: var(--app-font-family);
  }

  .ant-table {
    background-color: transparent;
    color: white;
    font-family: var(--app-font-family);
  }

  .ant-table-thead > tr > th {
    background-color: #2C7D6E;
    color: black;
    border-bottom: 1px solid #303030;
    border-right: none !important;
    text-align: center;
    transition: background-color 0.3s, color 0.3s;
    font-family: var(--app-font-family);

    &:hover {
      background-color: #95F7E0 !important;
      color: black !important;

      .ant-table-column-sorter {
        color: black;
      }
      .ant-table-filter-trigger {
        color: black;
      }
    }

    .ant-table-column-sorter {
      color: black;
    }

    &.ant-table-column-sort {
      background-color: #95F7E0 !important;
      color: black !important;

      .ant-table-column-sorter {
        color: black;
      }

      .ant-table-filter-trigger {
        color: black;
      }
    }
  }

  .ant-table-filter-trigger {
    color: black;
  }

  .ant-table-filter-column {
    .ant-table-filter-trigger.active {
      background-color: #95F7E0 !important;
    }
  }

  .ant-table-filter-trigger.active {
    color: #FEEA75 !important;
  }

  .ant-table-filter-dropdown {
    background-color: #212428;
    border-color: #303030;
  }

  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: #747E7D !important;
  }

  .ant-table-thead > tr > th::before {
    display: none !important;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #303030;
    text-align: center;
    background-color: transparent;
    font-family: var(--app-font-family);
  }

  .ant-table-tbody > tr:nth-child(even) {
    background-color: #212428;
  }

  .ant-table-tbody > tr:hover > td {
    background-color: #95F7E0 !important;
    color: black !important;
  }

  .ant-table-cell {
    border-right: none;
  }

  .ant-table-container {
    border: 1px solid #303030;
  }

  .ant-pagination {
    font-family: var(--app-font-family);

    .ant-pagination-item {
      background-color: #212428;
      border-color: #303030;
      a {
        color: #747E7D;
      }
    }

    .ant-pagination-item-active {
      background-color: #212428;
      border-color: #303030;
      a {
        color: white;
      }
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      .ant-pagination-item-link {
        background-color: #212428;
        border-color: #303030;
        color: #747E7D;
      }
    }

    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
      .ant-pagination-item-container {
        .ant-pagination-item-link-icon {
          color: #747E7D;
        }
        .ant-pagination-item-ellipsis {
          color: #747E7D;
        }
      }
    }

    .ant-pagination-prev:hover,
    .ant-pagination-next:hover {
      .ant-pagination-item-link {
        color: #747E7D;
      }
    }

    .ant-pagination-jump-prev:hover,
    .ant-pagination-jump-next:hover {
      .ant-pagination-item-container {
        .ant-pagination-item-link-icon {
          color: #747E7D;
        }
      }
    }

    .ant-pagination-item:hover {
      border-color: #303030;
      a {
        color: white;
      }
    }

    .ant-pagination-options {
      .ant-select-selector {
        background-color: #212428 !important;
        border-color: #303030 !important;
        color: #747E7D !important;
      }

      .ant-select-arrow {
        color: #303030;
      }

      &:hover {
        .ant-select-selector {
          border-color: #303030 !important;
        }
        .ant-select-arrow {
          color: #747E7D;
        }
      }
    }
  }

  .ant-select-dropdown {
    background-color: #212428 !important;
    border-color: #303030;

    .ant-select-item {
      color: white;

      &:hover {
        background-color: #95F7E0;
        color: black;
      }
    }

    .ant-select-item-option-selected {
      background-color: #303030;
      color: white;
    }
  }

  // Fixed column styles
  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    z-index: 2 !important;
  }

  .ant-table-cell-fix-left-last::after {
    box-shadow: inset 60px 0 8px -50px rgba(0, 0, 0, 0.15) !important;
  }

  .ant-table-cell-fix-right-first::after {
    box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15) !important;
  }

  .ant-table-cell-fix-left {
    position: sticky !important;
    left: 0 !important;
  }

  .ant-table-cell-fix-right {
    position: sticky !important;
    right: 0 !important;
  }

  // Ensure fixed columns maintain correct background
  .ant-table-tbody > tr > td.ant-table-cell-fix-left,
  .ant-table-tbody > tr > td.ant-table-cell-fix-right {
    background-color: #0F1A1E;
  }

  .ant-table-tbody > tr:nth-child(even) > td.ant-table-cell-fix-left,
  .ant-table-tbody > tr:nth-child(even) > td.ant-table-cell-fix-right {
    background-color: #212428;
  }

  .ant-table-tbody > tr:hover > td.ant-table-cell-fix-left,
  .ant-table-tbody > tr:hover > td.ant-table-cell-fix-right {
    background-color: #95F7E0 !important;
  }
`;

export const ModernInput = styled(Input)`
  && {
    font-family: var(--app-font-family);
  }

  &.ant-input {
    background-color: #212428;
    border-color: #303030;
    color: #fff;
  }

  &.ant-input:focus {
    border-color: #bdc3c7;
    box-shadow: 0 0 8px rgba(52, 152, 219, 0.5);
  }

  &.ant-input:hover {
    border-color: #bdc3c7;
  }

  &.ant-input::placeholder {
    color: #95a5a6;
  }

  &.ant-input:disabled {
    background-color: #ecf0f1;
    border-color: #bdc3c7;
    color: #7f8c8d;
    cursor: not-allowed;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

export const ModernButton = styled(Button)`
  && {
    font-family: var(--app-font-family);
  }

  &.ant-btn {
    margin-top: 10px;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    color: black;
  }

  &.ant-btn:hover, &.ant-btn:focus {
    cursor: pointer;
  }

  &.ant-btn-primary {
    background-color: #3498db;
    &:hover, &:focus {
      opacity: 0.8;
    }
  }

  &.ant-btn-warning {
    background-color: #FEEA75;
    &:hover, &:focus {
      opacity: 0.8;
    }
  }

  &.ant-btn-default {
    background-color: var(--primary-color);
    &:hover, &:focus {
      background-color: var(--primary-color) !important;
      color: black !important;
    }
  }
`;

export const ModernSelect = styled(Select)`
  && {
    font-family: var(--app-font-family);
  }

  &.ant-select .ant-select-selector {
    background-color: #212428;
    border-color: #303030;
    color: white !important;
  }

  &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #bdc3c7;
  }
`;

export const ModernDatePicker = styled(DatePicker)`
  && {
    font-family: var(--app-font-family);
  }

  &.ant-picker {
    background-color: #212428;
    border-color: #303030;
    color: #fff !important;
  }

  &.ant-picker:hover, &.ant-picker-focused {
    border-color: #bdc3c7;
  }

  .ant-picker-input {
    color: white !important;
  }

  .ant-picker-input > input {
    color: #fff !important;
  }
`;