// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @import "./../../index.css"; */


.toggle-group {
    width: fit-content;
    display: flex;
    border-radius: 8px;
    /* overflow: hidden; */
    border: 2px solid var(--border-color);
  }
  .toggle-button {
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 500;
    background-color:transparent;
    color: var(--text-color);
    border: none;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  .toggle-button:hover {
    color:var(--primary-color);
  }
  .toggle-button.selected {
    background-color:var(--primary-color);
    color:black;
  }
  .toggle-button:not(:last-child) {
    border-right: 1px solid var(--border-color);
  }


  .horizontal-line {
    border-top: 3px solid var(--border-color);
    width: 105%;
    margin: 20px 0;
  }
`, "",{"version":3,"sources":["webpack://./src/css/utils/utils.css"],"names":[],"mappings":"AAAA,iCAAiC;;;AAGjC;IACI,kBAAkB;IAClB,aAAa;IACb,kBAAkB;IAClB,sBAAsB;IACtB,qCAAqC;EACvC;EACA;IACE,iBAAiB;IACjB,eAAe;IACf,gBAAgB;IAChB,4BAA4B;IAC5B,wBAAwB;IACxB,YAAY;IACZ,eAAe;IACf,6CAA6C;EAC/C;EACA;IACE,0BAA0B;EAC5B;EACA;IACE,qCAAqC;IACrC,WAAW;EACb;EACA;IACE,2CAA2C;EAC7C;;;EAGA;IACE,yCAAyC;IACzC,WAAW;IACX,cAAc;EAChB","sourcesContent":["/* @import \"./../../index.css\"; */\n\n\n.toggle-group {\n    width: fit-content;\n    display: flex;\n    border-radius: 8px;\n    /* overflow: hidden; */\n    border: 2px solid var(--border-color);\n  }\n  .toggle-button {\n    padding: 8px 12px;\n    font-size: 14px;\n    font-weight: 500;\n    background-color:transparent;\n    color: var(--text-color);\n    border: none;\n    cursor: pointer;\n    transition: background-color 0.3s, color 0.3s;\n  }\n  .toggle-button:hover {\n    color:var(--primary-color);\n  }\n  .toggle-button.selected {\n    background-color:var(--primary-color);\n    color:black;\n  }\n  .toggle-button:not(:last-child) {\n    border-right: 1px solid var(--border-color);\n  }\n\n\n  .horizontal-line {\n    border-top: 3px solid var(--border-color);\n    width: 105%;\n    margin: 20px 0;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
