import React, { useState } from 'react';
import { ArrowUp, ArrowDown, ArrowUpDown } from 'lucide-react';
import "./../../css/dashboard/symbolMetricsTable.css";
import { formatNumber, symbol_dict } from '../utils/utils';

const PositionTable = ({ positions_metrics }) => {

    positions_metrics = positions_metrics.map(item => ({
        ...item,
        Coin: symbol_dict[item.Coin] ? symbol_dict[item.Coin].split("/")[0] : item.Coin.split("/")[0]
    }));

    const [sortConfig, setSortConfig] = useState({ key: 'Unrealized PnL', direction: 'descending' });

    const sortedData = React.useMemo(() => {
        let sortableItems = [...positions_metrics];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [positions_metrics, sortConfig]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = (columnName) => {
        if (sortConfig.key === columnName) {
            return sortConfig.direction === 'ascending' ?
                <ArrowUp className="sort-icon" size={16} /> :
                <ArrowDown className="sort-icon" size={16} />;
        }
        return <ArrowUpDown className="sort-icon" size={16} />;
    };

    return (
        <div className="symbol-metrics-table">
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th onClick={() => requestSort('Coin')}>
                                Coin {getSortIcon('Coin')}
                            </th>
                            <th onClick={() => requestSort('Size')}>
                                Size {getSortIcon('Size')}
                            </th>
                            <th onClick={() => requestSort('Position Value')}>
                                Position Value {getSortIcon('Position Value')}
                            </th>
                            <th onClick={() => requestSort('Entry Price')}>
                                Entry Price {getSortIcon('Entry Price')}
                            </th>
                            <th onClick={() => requestSort('Unrealized PnL')}>
                                Unrealized PnL {getSortIcon('Unrealized PnL')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData.map((metric, index) => (
                            <tr key={index}>
                                <td style={{ color: metric['Size'] < 0 ? "#D19EA7" : "#50D2C1" }}>{metric['Coin']}</td>
                                <td style={{ color: metric['Size'] < 0 ? "#FF0000" : "#95F7E0" }}>{formatNumber(metric['Size'])}</td>
                                <td>{formatNumber(metric['Position Value'])}</td>
                                <td>{formatNumber(metric['Entry Price'])}</td>
                                <td className={metric['Unrealized PnL'] >= 0 ? 'positive' : 'negative'}>
                                    {formatNumber(metric['Unrealized PnL'])}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default PositionTable;