import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Dashboard from './dashboard';
import "./../../css/dashboard/portfolio.css"
import { Loader2 } from "lucide-react"
import api_url from "./../../config/api_and_url.json"


const base_api = api_url.base_api
const API_URL = base_api + '/trade_history';

const Portfolio = () => {
    const [walletData, setWalletData] = useState(null);
    const [walletAddress, setWalletAddress] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const addressParam = searchParams.get('address');
        if (addressParam) {
            setWalletAddress(addressParam);
            fetchWalletData(addressParam);
        }
    }, [location]);

    const fetchWalletData = async (address) => {
        setWalletData(null);
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch(API_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ address: address }),
            });

            if (!response.ok) {
                if (response.status === 500) {
                    throw new Error(`There is an issue, we are working to fix this. Got status code ${response.status}`);
                } else {
                    throw new Error(`Failed to fetch wallet data. Got status code ${response.status}`);
                }
            }

            const data = await response.json();
            setWalletData(data);
        } catch (err) {
            setError(err.message);
            setWalletData(null);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (walletAddress) {
            navigate(`/portfolio?address=${walletAddress}`);
        }
    };

    return (
        <div >
            {!walletData &&
                <div className={!isLoading&&!error ?"wallet_search_container":"wallet_search_container_2"}>
                    < form onSubmit={handleSubmit} className="">
                        <input
                            type="text"
                            value={walletAddress}
                            onChange={(e) => setWalletAddress(e.target.value)}
                            placeholder="Enter wallet address"
                            className="modern-input"
                        />
                        <button type="submit" className="analyze_button" disabled={isLoading}>
                            {isLoading ? 'Analyzing...' : 'Analyze'}
                        </button>
                    </form>
                </div>
            }

            {
                isLoading && (
                    <div className="loader_card">
                        <div className="loader-container">
                            <Loader2
                                size={64}
                                strokeWidth={1}
                                absoluteStrokeWidth={false}
                                className="animate-spin"
                            />
                        </div>
                        <span className="">Loading wallet data...</span>
                    </div>
                )
            }

            {
                error && (
                    <div className="error_card">
                        Error: {error}
                    </div>
                )
            }

            {
                walletData && (
                    <div className="dashBoard_head">
                        {/* <h1 className="dashBoard_heading">Hyperliquid Trading Metrics</h1> */}
                        <div>
                            <h3 className="wallet_add">
                                <span className="">Wallet Address: </span>
                                <span className="">{walletAddress}</span>
                            </h3>
                        </div>
                        <div className="wallet_note">
                            <h5>
                                We show only realized PnL with assumption of 2 bps fee per trade. Analytics are based on the last 10,000 transactions or all if fewer.
                            </h5>
                        </div>
                    </div>
                )
            }

            {walletData && <Dashboard walletData={walletData} />}
        </div >
    );
};

export default Portfolio;