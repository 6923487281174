// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./index.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
body, input, button, select, .ant-select-selection-item, .ant-select-selection-placeholder {
  font-family: var(--app-font-family) !important;
}

.main2 {
  /* top: 20; */
  margin: 30px 50px 100px 50px;
  padding-top: 40px
}

/* Responsive adjustments */
@media screen and (max-width: 1105px) {
  .main2 {
    margin: 80px 30px;
  }
}

/* @media screen and (min-width: 1024px) {
  .main2 {
    margin: 30px 100px 100px 100px;
  }
} */`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AAGA;EACE,8CAA8C;AAChD;;AAEA;EACE,aAAa;EACb,4BAA4B;EAC5B;AACF;;AAEA,2BAA2B;AAC3B;EACE;IACE,iBAAiB;EACnB;AACF;;AAEA;;;;GAIG","sourcesContent":["\n@import \"./index.css\";\n\nbody, input, button, select, .ant-select-selection-item, .ant-select-selection-placeholder {\n  font-family: var(--app-font-family) !important;\n}\n\n.main2 {\n  /* top: 20; */\n  margin: 30px 50px 100px 50px;\n  padding-top: 40px\n}\n\n/* Responsive adjustments */\n@media screen and (max-width: 1105px) {\n  .main2 {\n    margin: 80px 30px;\n  }\n}\n\n/* @media screen and (min-width: 1024px) {\n  .main2 {\n    margin: 30px 100px 100px 100px;\n  }\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
